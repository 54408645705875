import $ from 'jquery';
import React,{useState,useEffect} from 'react';
import {ListTicketPriority} from './default_data_ticket';
import {GetImgCertUserCMS_CBack} from '../function_lib/common_cms';

export function submitNewTicket(){
  GetImgCertUserCMS_CBack(this,"#frmCmsAddNew","addNewContentCMS",function(checkData,checkMsg,userMsg){
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        alert(checkMsg);
        $('#divCmsAddNew').modal('hide');
        window.location.reload();
      }
    });
}             
  
export const Client_Create_Ticket=(props)=>{
  //alert(JSON.stringify(props.listMenus));
  const [getCompList,setCompList]=useState([]);
    const handleMapMenuChg=(event)=>{
        let tarValue=event.target.value;
        if(tarValue!=="0" && tarValue!==0){
            tarValue=atob(tarValue);
        }
        const listFltr = d =>d.parent_id===tarValue;
        let listComps=props.listAllComp.filter(listFltr);
        setCompList(listComps);
    }
    const listPriority=ListTicketPriority();
    useEffect(()=>{

    },[])
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divCreateSupportTicket" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Create New Ticket</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form class="form-group" id="frmCreateSupportTicket" name="frmCreateSupportTicket" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addNewContentCMS" />
                            <input type="hidden" name="m_type" id="m_type" value="addNewContentCMS" />
                            {/* <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} /> */}
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsOthImages" id="lsOthImages"/>
                            <input type="hidden" name="bNode" id="bNode" value={props.b_node}/>
                            <input type="hidden" name="pNode" id="pNode" value={props.p_node}/>
                            <input type="hidden" name="col20" id="col20" value={props.p_node}/>
                            <input type="hidden" name="type" id="type" value={"20"}/>
                          
                            <div class="card-body">
                                <div className="row">
                                  
                                   
                                
                                  
                                
                                <div className="col-md-8 col-lg-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Subject</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col1" name="col1" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Priority</label>
                                            <div className="input-group input-group-sm">
                                                <select id="menu_add_id" name="menu_add_id" onChange={handleMapMenuChg} class="form-control form-control-sm">
                                                <option value="0">-Option-</option>
                                                        {listPriority.map((item,index)=>{
                                                          return (<option value={btoa(item.value)}>{item.label}</option>)
                                                        })}

                                                </select>
                                            </div>
                                        </div>
                                </div>
                                    
                                </div>
                                <div class="row">
                                 
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="col2" name="col2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                  
{/* image upload section */}
<div className="col-md-12">
<div className="row">
    

                               {/* file other image upload */}

<div className="col-md-8">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Images or Other Attachment </h3><small> (Max File Size 1MB, Only 7 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgOthLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNew" thumbnail_div={"oth_img_div"} lsElement={"lsOthImages"} thumbnail={"imgOthLibThumb"} multiple name="customOthFileBr" id="customOthFileBr" accept="image/*" size={100000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customOthFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of other file image upload */}
</div>
</div>
{/* end of Image Upload Section */}

                                </div>
                                 
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>submitNewTicket()} class="btn btn-primary">Submit</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
          </div>
          </div>
          </div>
          </div>);
    
}
export default Client_Create_Ticket;
