import React,{useState,useEffect} from 'react';
import Client_Create_Ticket from './client_create_ticket';

export const Client_Interface=(props)=>{

    return(<div>
        
        <div className="content">
        <div className="container-fluid mt-4">
        <div className="row content12 mt-2 mb-2">
        
        
            </div>    
        <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                 
              <div class="card">
                      {/* <!-- /.card-header --> */}
                   <div class="card-header bg-cyan">
                        <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Data List</b></h3>
                        
                        {/* option of page sections */}
                        <div class="input-group-prepend float-right">
                            <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                              Create New
                            </button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" data-toggle="modal" data-target="#divCreateSupportTicket" href="javascript:void(0)">Create New Ticket</a>
                              {/* <a class="dropdown-item" data-toggle="modal" data-target="#divAddHeader" href="javascript:void(0)">New Section to Menu</a>
                              <a class="dropdown-item" data-toggle="modal" data-target="#divCmsAddNew" href="javascript:void(0)">Add New Data</a> */}
                              {/* <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} href="javascript:void(0)">Edit</a>
                              <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} data-toggle="modal" data-target="#divPtrInvoice" href="javascript:void(0)">Delete</a> */}
                             
                            </div>
                          </div>
                        {/* end of page sections */}
        
                      </div>
                        <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                          <thead>
                            <tr class="border">
                              <th>S.No.</th>
                              <th>Status</th>
                              <th>Last Replied</th>
                              <th>Ticket Id</th>
                              <th>Desc</th>
                              <th>Last Replier</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          
                          </tbody>
                        </table>
                        <div class='pagination-container' >
                        <nav>
                          <ul class="pagination">
                    
                    <li data-page="prev" >
                                             <span> {"<"} <span class="sr-only">(window.current)</span></span>
                                            </li>
                          
                <li data-page="next" id="prev">
                                               <span> {">"} <span class="sr-only">(window.current)</span></span>
                                            </li>
                          </ul>
                        </nav>
                    </div>
                      {/* <!-- /.card-body --> */}
                    </div>
              </div>
        </div>
        </div>
        </div>
        <Client_Create_Ticket/>
           </div>)
}

export default Client_Interface;