export const ListTicketPriority=()=>{
    return [
        {label:"Low",value:"Low"},
        {label:"Medium",value:"Medium"},
        {label:"High",value:"High"},
    ]
}
export const TicketStatus=()=>{
    return[
        {label:"Open",value:"Open"},
        {label:"Hold",value:"Hold"},
        {label:"Customer Replied",value:"Customer Replied"},
        {label:"Support Replied",value:"Support Replied"},
        {label:"Closed",value:"Closed"}
    ]
}